import React, { useEffect, useRef } from "react"
import parse from "html-react-parser"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

import Close from "../../images/close.svg"

export default function PopupTeam(props) {
  const pop = useRef(null)

  useEffect(() => {
    clearAllBodyScrollLocks()
  })

  !props.opened ? enableBodyScroll(pop) : disableBodyScroll(pop)

  return (
    <div
      className="popup-wrapper"
      style={{ display: props.opened ? "flex" : "none" }}
      ref={pop}
    >
      <div className="popup-wrapper__container grid-8-col">
        <button
          className="popup-wrapper__container__close"
          onClick={() => {
            props.setOpened(false)
          }}
        >
          <img src={Close} alt="" />
        </button>

        {props.member.MemberGQL.profileImage ? (
          <GatsbyImage
            draggable="false"
            className="col-3 popup-wrapper__container__image"
            alt={props.member.MemberGQL.name}
            image={
              props.member.MemberGQL.profileImage.localFile.childImageSharp
                .gatsbyImageData
            }
          />
        ) : (
          <StaticImage
            className="col-3 popup-wrapper__container__image"
            draggable="false"
            src="../../images/Avatar.png"
            layout="fullWidth"
            aspectRatio={0.81}
            alt="Profile image"
          />
        )}

        <div className="col-4">
          <h2>{props.member.MemberGQL.name}</h2>
          <p className="popup-wrapper__container__role">
            {props.member.MemberGQL.role.toUpperCase()}
          </p>

          {props.member.MemberGQL.linkToCienciaVitae && (
            <a
              href={props.member.MemberGQL.linkToCienciaVitae}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ciência Vitae
            </a>
          )}

          {props.member.MemberGQL.shortBio && (
            <>
              <br />
              <br />
              {parse(props.member.MemberGQL.shortBio)}
            </>
          )}

          {props.member.MemberGQL.academicDegrees && (
            <>
              <br />
              <br />
              <span>Academic Degrees</span>
              {parse(props.member.MemberGQL.academicDegrees)}
            </>
          )}

          {props.member.MemberGQL.researchInterests && (
            <>
              <br />
              <br />
              <span>Research Interests</span>
              {parse(props.member.MemberGQL.researchInterests)}
              <br />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
