import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import moment from "moment"

import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
import Seo from "../components/Seo"
import PopupTeam from "../components/PopupTeam/PopupTeam"

const TheGroup = ({
  data: {
    allWpJob,
    allWpMember,
    PrincipalInvestigator,
    InvitedAuxiliaryResearcher,
    AssistantInvestigator,
    LabManager,
  },
}) => {
  const jobs = allWpJob.nodes

  let totalJobs = jobs.length

  for (let i = 0; i < jobs.length; i++) {
    const job = jobs[i]

    if (moment().isAfter(job.JobGQL.submissionDeadline, "day")) {
      totalJobs = totalJobs - 1
    }
  }

  const members = allWpMember.nodes

  const [opened, setOpened] = useState(true)
  const [activeMember, setActiveMember] = useState("")

  return (
    <React.Fragment>
      <Seo title={"The Group"} />
      <Navbar />
      <main className="marginTopNavbar">
        <section className="small-hero">
          <StaticImage
            draggable="false"
            src="../images/Vimentin Fibrob2.jpg"
            layout="fullWidth"
            loading="eager"
            style={{ height: "100%" }}
            alt="Image hero ATG"
          />
        </section>
        {/* <div className="main-container"> */}
        <div className="main-container">
          <section className="overview">
            <div id="overview" className="offset" />
            <div className="grid-8-col">
              <div className="col-3 ">
                <h1 className="sticky">Overview</h1>
              </div>
              <div className="col-4">
                <p>
                  The research group of biomaterials and stem cell-based
                  therapeutics was created in January 2008 at CNC. The research
                  group aims at generating fundamental and translational
                  knowledge in the intersection of biomaterials with stem cells.
                  The research group has two main avenues of research:
                </p>
                <br />
                <p>
                  Disease modeling and drug screening program: in vitro
                  cell/tissue models from human stem cells. Stem cells, in
                  particular induced pluripotent stem cells (iPSCs), may be an
                  excellent source of cells for disease modeling and drug
                  discovery programs related to cardiovascular diseases. The
                  potential of iPSCs to generate disease models led to the
                  creation of several biobanks in USA (Coriell Institute for
                  Medical Research, NIH Center for Regenerative Medicine, ATCC
                  and University owned biobanks), Europe (Cellartis; and an
                  European initiative of Stem cell biobank) and Japan (RIKEN
                  bioresource center) for storage and distribution of iPSC lines
                  originated from patients and healthy controls.
                </p>
                <br />
                <p>
                  In the last 6 years my research group has developed several
                  tissue models from stem cells that may be an important
                  platform for drug discovery programs related to cardiovascular
                  diseases. A particular interest of the group is to develop
                  biomaterials and bioengineering platforms for the efficient
                  maturation/specification of stem cells and their progenies.
                </p>
                <br />
                <p>
                  The research group uses many tools to accomplish this goal,
                  including the design of new biomaterials with relevant
                  biological information, molecular and cell biology,
                  microfluidic systems, high content analysis, and animal
                  experimentation. Nanomedicine platforms to modulate the
                  activity of stem cells and their progenies. The development of
                  a wide spectrum of nanotechnologies (referred as Nanomedicine
                  by National Institutes of Health for applications in the
                  biomedical area) during the last years are very promising for
                  the study of stem cell biology and to control exogenous and
                  endogenous stem cells for regenerative medicine.
                </p>
                <br />
                <p>
                  Our group is particularly interested to use these tools to
                  induce in vivo stem cell differentiation and to mobilize stem
                  cells from their niches to treat cardiovascular diseases. For
                  this purpose, we are developing nanomaterials that release
                  efficiently biomolecules (small molecules, proteins or
                  non-coding RNAs) to manipulate stem cells or their progenies.
                </p>
              </div>
            </div>
          </section>
        </div>

        <div className="horizontal-line" />

        <section className="small-hero">
          <StaticImage
            draggable="false"
            src="../images/team_hero.jpg"
            layout="fullWidth"
            loading="eager"
            style={{ height: "100%" }}
            alt="Image hero ATG"
          />
        </section>

        <div className="main-container">
          <section className="latest-news">
            <div id="team" className="offset" />
            <h1 className="latest-news__title">Team</h1>

            {PrincipalInvestigator &&
              <>
                <div className="team__items">
                  {PrincipalInvestigator.MemberGQL.profileImage ? (
                    <GatsbyImage
                      draggable="false"
                      className="team__items__image__big"
                      alt={PrincipalInvestigator.MemberGQL.name}
                      image={
                        PrincipalInvestigator.MemberGQL.profileImage.localFile
                          .childImageSharp.gatsbyImageData
                      }
                    />
                  ) : (
                    <StaticImage
                      className="team__items__image__big"
                      draggable="false"
                      src="../images/Avatar.png"
                      layout="fullWidth"
                      aspectRatio={0.81}
                      alt="Profile image"
                    />
                  )}

                  <div className="col-4">
                    <h2>{PrincipalInvestigator.MemberGQL.name}</h2>
                    <p className="team__items__role">
                      {PrincipalInvestigator.MemberGQL.role.toUpperCase()}
                    </p>
                    <a
                      href={PrincipalInvestigator.MemberGQL.linkToCienciaVitae}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ciência Vitae
                    </a>

                    {PrincipalInvestigator.MemberGQL.shortBio && (
                      <>
                        <br />
                        <br />

                        {parse(PrincipalInvestigator.MemberGQL.shortBio)}
                      </>
                    )}

                    {PrincipalInvestigator.MemberGQL.academicDegrees && (
                      <>
                        <br />
                        <br />
                        <span>Academic Degrees</span>
                        {parse(PrincipalInvestigator.MemberGQL.academicDegrees)}
                      </>
                    )}

                    {PrincipalInvestigator.MemberGQL.researchInterests && (
                      <>
                        <br />
                        <span>Research Interests</span>
                        {parse(PrincipalInvestigator.MemberGQL.researchInterests)}
                      </>
                    )}
                  </div>
                </div>
                <div className="horizontal-line" />
              </>
            }
                
            {AssistantInvestigator &&
    <>
            <div className="team__items">
              {AssistantInvestigator?.MemberGQL?.profileImage ? (
                <GatsbyImage
                  className=" team__items__image__big"
                  alt={AssistantInvestigator?.MemberGQL.name}
                  image={
                    AssistantInvestigator?.MemberGQL.profileImage.localFile
                      .childImageSharp.gatsbyImageData
                  }
                />
              ) : (
                <StaticImage
                  className="team__items__image__big"
                  draggable="false"
                  src="../images/Avatar.png"
                  layout="fullWidth"
                  aspectRatio={0.81}
                  alt="Profile image"
                />
              )}
              <div className="col-4">
                <h2>{AssistantInvestigator?.MemberGQL.name}</h2>
                <p className="team__items__role">
                  {AssistantInvestigator?.MemberGQL.role.toUpperCase()}
                </p>
                <a
                  href={AssistantInvestigator?.MemberGQL.linkToCienciaVitae}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ciência Vitae
                </a>

                {AssistantInvestigator?.MemberGQL.shortBio && (
                  <>
                    <br />
                    <br />
                    {parse(AssistantInvestigator?.MemberGQL.shortBio)}
                  </>
                )}

                {AssistantInvestigator?.MemberGQL.academicDegrees && (
                  <>
                    <br />
                    <br />
                    <span>Academic Degrees</span>
                    {parse(AssistantInvestigator?.MemberGQL.academicDegrees)}
                  </>
                )}

                {AssistantInvestigator?.MemberGQL.researchInterests && (
                  <>
                    <br />
                    <span>Research Interests</span>
                    {parse(AssistantInvestigator?.MemberGQL.researchInterests)}
                  </>
                )}
              </div>
            </div>
            <div className="horizontal-line" />
            </>
            }

            {InvitedAuxiliaryResearcher &&
            <>
            <div className="team__items ">
              {InvitedAuxiliaryResearcher.MemberGQL.profileImage ? (
                <GatsbyImage
                  className="team__items__image__big"
                  alt={InvitedAuxiliaryResearcher.MemberGQL.name}
                  image={
                    InvitedAuxiliaryResearcher.MemberGQL.profileImage.localFile
                      .childImageSharp.gatsbyImageData
                  }
                />
              ) : (
                <StaticImage
                  className="team__items__image__big"
                  draggable="false"
                  src="../images/Avatar.png"
                  layout="fullWidth"
                  aspectRatio={0.81}
                  alt="Profile image"
                />
              )}

              <div className="col-4">
                <h2>{InvitedAuxiliaryResearcher.MemberGQL.name}</h2>
                <p className="team__items__role">
                  {InvitedAuxiliaryResearcher.MemberGQL.role.toUpperCase()}
                </p>
                <a
                  href={InvitedAuxiliaryResearcher.MemberGQL.linkToCienciaVitae}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ciência Vitae
                </a>

                {InvitedAuxiliaryResearcher.MemberGQL.shortBio && (
                  <>
                    <br />
                    <br />
                    {parse(InvitedAuxiliaryResearcher.MemberGQL.shortBio)}
                  </>
                )}

                {InvitedAuxiliaryResearcher.MemberGQL.academicDegrees && (
                  <>
                    <br />
                    <br />
                    <span>Academic Degrees</span>
                    {parse(
                      InvitedAuxiliaryResearcher.MemberGQL.academicDegrees
                    )}
                  </>
                )}

                {InvitedAuxiliaryResearcher.MemberGQL.researchInterests && (
                  <>
                    <br />
                    <span>Research Interests</span>
                    {parse(
                      InvitedAuxiliaryResearcher.MemberGQL.researchInterests
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="horizontal-line" />
            </>
      }

            {LabManager && 
            <>
            <div className="team__items">
              {LabManager.MemberGQL.profileImage ? (
                <GatsbyImage
                  className="team__items__image__big"
                  alt={LabManager.MemberGQL.name}
                  image={
                    LabManager.MemberGQL.profileImage.localFile.childImageSharp
                      .gatsbyImageData
                  }
                />
              ) : (
                <StaticImage
                  className="team__items__image__big"
                  draggable="false"
                  src="../images/Avatar.png"
                  layout="fullWidth"
                  aspectRatio={0.81}
                  alt="Profile image"
                />
              )}

              <div className="col-4">
                <h2>{LabManager.MemberGQL.name}</h2>
                <p className="team__items__role">
                  {LabManager.MemberGQL.role.toUpperCase()}
                </p>
                <a
                  href={LabManager.MemberGQL.linkToCienciaVitae}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ciência Vitae
                </a>

                {LabManager.MemberGQL.shortBio && (
                  <>
                    <br />
                    <br />

                    {parse(LabManager.MemberGQL.shortBio)}
                  </>
                )}

                {LabManager.MemberGQL.academicDegrees && (
                  <>
                    <br />
                    <br />
                    <span>Academic Degrees</span>
                    {parse(LabManager.MemberGQL.academicDegrees)}
                  </>
                )}

                {LabManager.MemberGQL.researchInterests && (
                  <>
                    <br />
                    <span>Research Interests</span>
                    {parse(LabManager.MemberGQL.researchInterests)}
                  </>
                )}
              </div>
            </div>
            <div className="horizontal-line" />
            </>}

            <div className="team__items grid-8-col" style={{ margin: 0 }}>
              <div className="col-8">
                <h2>Post Doctorates</h2>
              </div>
              {members.map((member, memberIndex) => {
                if (member.MemberGQL.role === "PostDoc") {
                  return (
                    <div className="col-2" key={memberIndex}>
                      {member.MemberGQL.profileImage ? (
                        <GatsbyImage
                          className="col-2 team__items__image"
                          alt={member.MemberGQL.name}
                          image={
                            member.MemberGQL.profileImage.localFile
                              .childImageSharp.gatsbyImageData
                          }
                        />
                      ) : (
                        <StaticImage
                          className="col-2 team__items__image"
                          draggable="false"
                          src="../images/Avatar.png"
                          layout="fullWidth"
                          aspectRatio={0.81}
                          alt="Profile image"
                        />
                      )}

                      <h2 className="team__items__title">
                        {member.MemberGQL.name}
                      </h2>
                      <p className="team__items__role">
                        {member.MemberGQL.role}
                      </p>

                      <button
                        className="team__items__view-more"
                        onClick={() => {
                          setActiveMember(memberIndex)
                          setOpened(true)
                        }}
                      >
                        Read Bio
                      </button>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>

            <div className="horizontal-line" />

            <div className="team__items col-8 grid-8-col" style={{ margin: 0 }}>
              <div className="col-8">
                <h2>Graduates</h2>
              </div>
              {members.map((member, memberIndex) => {
                if (member.MemberGQL.role === "Graduates") {
                  return (
                    <div className="col-2" key={memberIndex}>
                      {member.MemberGQL.profileImage ? (
                        <GatsbyImage
                          className="col-2 team__items__image"
                          alt={member.MemberGQL.name}
                          image={
                            member.MemberGQL.profileImage.localFile
                              .childImageSharp.gatsbyImageData
                          }
                        />
                      ) : (
                        <StaticImage
                          className="col-2 team__items__image"
                          draggable="false"
                          src="../images/Avatar.png"
                          layout="fullWidth"
                          aspectRatio={0.81}
                          alt="Profile image"
                        />
                      )}
                      <h2 className="team__items__title">
                        {member.MemberGQL.name}
                      </h2>
                      <p className="team__items__role">
                        {member.MemberGQL.role}
                      </p>
                      <button
                        className="team__items__view-more"
                        onClick={() => {
                          setActiveMember(memberIndex)
                          setOpened(true)
                        }}
                      >
                        Read Bio
                      </button>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>

            <div className="horizontal-line" />

            <div className="team__items col-8 grid-8-col" style={{ margin: 0 }}>
              <div className="col-8">
                <h2>Research Assistants</h2>
              </div>
              {members.map((member, memberIndex) => {
                if (member.MemberGQL.role === "Research Assistants") {
                  return (
                    <div className="col-2" key={memberIndex}>
                      {member.MemberGQL.profileImage ? (
                        <GatsbyImage
                          className="col-2 team__items__image"
                          alt={member.MemberGQL.name}
                          image={
                            member.MemberGQL.profileImage.localFile
                              .childImageSharp.gatsbyImageData
                          }
                        />
                      ) : (
                        <StaticImage
                          className="col-2 team__items__image"
                          draggable="false"
                          src="../images/Avatar.png"
                          layout="fullWidth"
                          aspectRatio={0.81}
                          alt="Profile image"
                        />
                      )}
                      <h2 className="team__items__title">
                        {member.MemberGQL.name}
                      </h2>
                      <p className="team__items__role">
                        {member.MemberGQL.role}
                      </p>
                      <button
                        className="team__items__view-more"
                        onClick={() => {
                          setActiveMember(memberIndex)
                          setOpened(true)
                        }}
                      >
                        Read Bio
                      </button>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>

            <div className="horizontal-line" />

            <div className="team__items col-8 grid-8-col" style={{ margin: 0 }}>
              <div className="col-8">
                <h2>ALUMNI</h2>
              </div>
              {members.map((member, memberIndex) => {
                if (member.MemberGQL.role === "Alumni") {
                  return (
                    <div className="col-4" key={memberIndex}>
                      <h2 className="team__items__title">
                        {member.MemberGQL.name}
                      </h2>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>

            {activeMember && (
              <PopupTeam
                opened={opened}
                setOpened={setOpened}
                member={members[activeMember]}
              />
            )}
          </section>
          <div className="horizontal-line" />
        </div>

        <div className="main-container">
          <section className="job-opportunities">
            <div id="job-opportunities" className="offset" />
            <div className="grid-8-col job-card-wrapper">
              <h1 className="col-8 job-card-wrapper__title">
                Job Opportunities
              </h1>

              {totalJobs > 0 ? (
                jobs.map((job, jobIndex) => {
                  const name = job.JobGQL.name
                  const submissionDeadline = job.JobGQL.submissionDeadline
                  const linkToEracareers = job.JobGQL.linkToEracareers

                  return (
                    <div
                      key={jobIndex}
                      className="job-card col-4"
                      // style={{ display: submissionDeadline < today && "none" }}
                      style={{
                        display:
                          moment().isAfter(submissionDeadline, "day") && "none",
                      }}
                    >
                      <h2 className="job-card__title">{name}</h2>
                      <br />
                      <span className="job-card__subtitle">
                        Submission Deadline
                      </span>
                      <p className="job-card__deadline">
                        <time>{submissionDeadline}</time>
                      </p>
                      <br />
                      <a
                        href={linkToEracareers}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More ↗
                      </a>
                    </div>
                  )
                })
              ) : (
                <div className="col-8">
                  <h2>No job opportunity available at this time.</h2>
                </div>
              )}
            </div>
          </section>
        </div>
        {/* </div> */}
      </main>
      <Footer />
    </React.Fragment>
  )
}

export const query = graphql`
  {
    allWpJob(sort: { fields: JobGQL___submissionDeadline, order: ASC }) {
      nodes {
        JobGQL {
          submissionDeadline
          name
          linkToEracareers
        }
      }
    }
    allWpMember(sort: { fields: MemberGQL___name, order: ASC }) {
      nodes {
        date
        MemberGQL {
          name
          role
          shortBio
          researchInterests
          academicDegrees
          profileImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 0.81)
              }
            }
          }
          linkToCienciaVitae
        }
      }
    }
    PrincipalInvestigator: wpMember(
      MemberGQL: { role: { eq: "Principal Investigator" } }
    ) {
      MemberGQL {
        name
        role
        shortBio
        linkToCienciaVitae
        shortBio
        researchInterests
        academicDegrees
        profileImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                layout: CONSTRAINED
                aspectRatio: 0.81
              )
            }
          }
        }
      }
    }
    InvitedAuxiliaryResearcher: wpMember(
      MemberGQL: { role: { eq: "Invited Auxiliary Researcher" } }
    ) {
      MemberGQL {
        name
        role
        shortBio
        linkToCienciaVitae
        shortBio
        researchInterests
        academicDegrees
        profileImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                layout: CONSTRAINED
                aspectRatio: 0.81
              )
            }
          }
        }
      }
    }
    AssistantInvestigator: wpMember(
      MemberGQL: { role: { eq: "Assistant Investigator" } }
    ) {
      MemberGQL {
        name
        role
        shortBio
        linkToCienciaVitae
        shortBio
        researchInterests
        academicDegrees
        profileImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                layout: CONSTRAINED
                aspectRatio: 0.81
              )
            }
          }
        }
      }
    }
    LabManager: wpMember(MemberGQL: { role: { eq: "Lab Manager" } }) {
      MemberGQL {
        name
        role
        shortBio
        linkToCienciaVitae
        shortBio
        researchInterests
        academicDegrees
        profileImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                layout: CONSTRAINED
                aspectRatio: 0.81
              )
            }
          }
        }
      }
    }
  }
`

export default TheGroup
